import { Button, Carousel, Col, Row } from "antd";
import ProdactCard from "../cards/prodactCard";
import { ReactComponent as NextArrow } from "../../assets/images/nextArrow.svg";
import { ReactComponent as PrevArrow } from "../../assets/images/prevArrow.svg";
import { ReactComponent as NextArrowPrim } from "../../assets/images/nextArrowprim.svg";
import { ReactComponent as PrevArrowPrim } from "../../assets/images/prevArrowprim.svg";
import mikrotikLogo from "../../assets/images/Mikrotik-logo.png";
import ubiquitiLogo from "../../assets/images/Logo-Ubiquiti.png";
import qnapLogo from "../../assets/images/QNAP_LOGO.png";
import mmcLogo from "../../assets/images/MMC-logo-color-LD.png";
import dahuaLogo from "../../assets/images/Dahua_Technology_logo.svg.png";
import seagateLogo from "../../assets/images/Seagate-logo.png";
import zktecoLogo from "../../assets/images/163860.png";
import voltronicLogo from "../../assets/images/loghhho.png";

import product1 from "../../assets/images/products/IPC-HFW5831E-Z5E.png";
import product2 from "../../assets/images/products/RB4011iGSRM.png";
import product3 from "../../assets/images/products/TS-433.png";
import product4 from "../../assets/images/products/UAP-AC-HD.png";
import skeletonImg from "../../assets/images/skeletonImg.svg";


const Products = () => {
  return (
    <div className="products" id="products">
      <div className="title">Products</div>
      <Row justify="center">
        <Col span={19}>
          <div className="brands">
            <Carousel
              arrows={true}
              responsive={[
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 567,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              nextArrow={
                <Button>
                  <NextArrow />
                </Button>
              }
              prevArrow={
                <Button>
                  <PrevArrow />
                </Button>
              }
              slidesToShow={4}
              slidesToScroll={1}
              dots={false}
              autoplay
              autoplaySpeed={1500}
              className="brandslider"
            >
              <a
                rel="noreferrer"
                target={"_blank"}
                href="https://mikrotik.com/"
              >
                <img src={mikrotikLogo} alt="" />
              </a>
              <a rel="noreferrer" target={"_blank"} href="https://www.ui.com/">
                <img src={ubiquitiLogo} alt="" />
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="https://www.qnap.com/en"
              >
                <img src={qnapLogo} alt="" />
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="http://www.multimedia-connect.com/"
              >
                <img src={mmcLogo} alt="" />
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="https://www.dahuasecurity.com/"
              >
                <img src={dahuaLogo} alt="" />
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="https://www.seagate.com/em/en/support/warranty-and-replacements/embargo-country-notice/"
              >
                <img src={seagateLogo} alt="" />
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="https://www.zkteco.com/en/"
              >
                <img src={zktecoLogo} alt="" />
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="https://voltronicpower.com/"
              >
                <img src={voltronicLogo} alt="" />
              </a>
            </Carousel>
          </div>
          <Row justify="center">
            <Col span={19}>
              <div className="coming-soon">Coming Soon</div>
              <Carousel
                arrows={false}
                nextArrow={
                  <Button>
                    <PrevArrowPrim />
                  </Button>
                }
                prevArrow={
                  <Button>
                    <NextArrowPrim />
                  </Button>
                }
                responsive={[
                  {
                    breakpoint: 992,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 567,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ]}
                slidesToShow={4}
                slidesToScroll={4}
                dots={false}
                className="slider"
              >
                <div>
                  <ProdactCard
                    productTitle="IPC-HFW5831E-Z5E"
                    brand="DAHUA"
                    rate={2}
                    imgsrc={product1}
                  />
                </div>
                <div>
                  <ProdactCard
                    productTitle="RB4011iGS+RM"
                    brand="MIKROTIK"
                    rate={4}
                    imgsrc={product2}
                  />
                </div>
                <div>
                  <ProdactCard
                    productTitle="TS-433"
                    brand="QNAP"
                    rate={3}
                    imgsrc={product3}
                  />
                </div>
                <div>
                  <ProdactCard
                    productTitle="UAP-AC-HD"
                    brand="UNIFI"
                    rate={5}
                    imgsrc={product4}
                  />
                </div>
                <div>
                  <ProdactCard rate={2} imgsrc={skeletonImg} />
                </div>
                <div>
                  <ProdactCard rate={2} imgsrc={skeletonImg} />
                </div>
                <div>
                  <ProdactCard rate={2} imgsrc={skeletonImg} />
                </div>
              </Carousel>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Products;
