import { Button, Col, Input, Row } from "antd";
import { useState } from "react";
import { ScrollTo } from "../../services/scrollto";
import { Link, useLocation } from "react-router-dom";
import QuestionModal from "../../components/questionModal";
import logo from "../../assets/images/logo.png";
import { ReactComponent as GooglePlay } from "../../assets/images/GooglePlay.svg";
import { ReactComponent as AppStore } from "../../assets/images/AppStore.svg";
import { ReactComponent as WhatsApp } from "../../assets/images/whatsapp.svg";
import { ReactComponent as InfoEmail } from "../../assets/images/infoemail.svg";
import { ReactComponent as Facebook } from "../../assets/images/facebook.svg";
import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";

const Footer = () => {
  const location = useLocation();
  const [Question, setQuestion] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <div className="line" />
      <div className="footer">
        <Row justify="center">
          <Col span={19}>
            <Row className="title" align="middle">
              <Col className="footerlinks" span={24} lg={8}>
                <div className="titlelink">
                  <Link to={"/"}>
                    <img className="logo" src={logo} alt="" />
                  </Link>
                </div>
                {/* <ul className='footeritems'>
                  <li>
                    <a href='#'>About Us</a>
                  </li>
                  <li>
                    <a href='#'>Terms of Use</a>
                  </li>
                  <li>
                    <a href='#'>Privacy Policy</a>
                  </li>
                </ul> */}
              </Col>
              <Col className="footerlinks" span={24} lg={8}>
                <div className="titlelink">Customer Service</div>
                <ul className="footeritems">
                  <li>
                    {location.pathname === "/" ? (
                      <span
                        onClick={() => {
                          ScrollTo("contact-us");
                        }}
                      >
                        Contact us
                      </span>
                    ) : (
                      <Link to={"/"}>Contact us</Link>
                    )}
                  </li>
                  <li>
                    {location.pathname === "/" ? (
                      <span
                        onClick={() => {
                          ScrollTo("services");
                        }}
                      >
                        Services
                      </span>
                    ) : (
                      <Link to={"/"}> Services</Link>
                    )}
                  </li>
                  <li>
                    {location.pathname === "/" ? (
                      <span
                        onClick={() => {
                          ScrollTo("solutions");
                        }}
                      >
                        Solutions
                      </span>
                    ) : (
                      <Link to={"/"}>Solutions</Link>
                    )}
                  </li>
                  <li>
                    {location.pathname === "/" ? (
                      <span
                        onClick={() => {
                          ScrollTo("our-clients");
                        }}
                      >
                        Our Clients
                      </span>
                    ) : (
                      <Link to={"/"}>Our Clients</Link>
                    )}
                  </li>
                </ul>
              </Col>
              <Col className="footerlinks" span={24} lg={8}>
                <div className="coming-soon">Coming Soon</div>
                <div className="soon-container">
                  <div className="titlelink">My Account</div>
                  <ul className="footeritems">
                    <li>My Profile</li>
                    <li>My Cart</li>
                    <li>My Wish List</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row align="bottom" style={{ marginBottom: "20px" }}>
              <Col span={24} lg={8}>
                <div className="app">Download The App</div>
                <div className="applink">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.izone.ticketing_system_izon"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GooglePlay />
                  </a>
                  <a
                    href="https://apps.apple.com/app/izone-soluations/id1644071197"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AppStore />
                  </a>
                </div>
              </Col>
              <Col span={24} lg={8}>
                <div className="subinput">
                  <Input.Group compact>
                    <Input
                      style={{ width: "calc(100% - 160px)" }}
                      onChange={(e) => setQuestion(e.target.value)}
                      placeholder={"Write Your Question"}
                    />
                    <Button type="primary" onClick={showModal}>
                      Send
                    </Button>
                  </Input.Group>
                  <QuestionModal
                    question={Question}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={(Visible: any) =>
                      setIsModalVisible(Visible)
                    }
                  />
                </div>
              </Col>
              <Col span={24} lg={8}>
                <Row>
                  <Col lg={18} className="contact">
                    <div className="contact-link">
                      <Link to="/privacy-policy">
                        <div style={{ cursor: "pointer" }}>Privacy Policy</div>
                      </Link>
                    </div>
                    <div className="contact-link">
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href="https://api.WhatsApp.com/send?phone=+963956743743"
                      >
                        <WhatsApp />
                        <div>+963 956 743 743</div>
                      </a>
                    </div>
                    <div className="contact-link">
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href="mailto:info@izone-me.com"
                      >
                        <InfoEmail />
                        <div>info@izone-me.com</div>
                      </a>
                    </div>
                    <div className="follow contact-link">
                      <div>Follow Us</div>
                      <a
                        href="https://t.me/izone_solutions_sy"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Telegram />
                      </a>
                      <a
                        href="https://www.facebook.com/izone.solutions.sy"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Facebook />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="copyright">IZONE Solutions 2022-2023© </div>
    </>
  );
};
export default Footer;
