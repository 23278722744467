import { Col, Row } from "antd";
import { ReactComponent as CallIcon } from "../../assets/images/ic_call_24px.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/email.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/location.svg";

const ContactUs = () => {
  return (
    <div className="contactus" id="contact-us">
      <Row justify="center">
        <Col span={19}>
          <div className="title">Get In Touch</div>
          <div className="text">
            Contact us for a quote, help or join our team
          </div>
          <Row justify="center" className="contact">
            <Col span={24} lg={6}>
              <a rel="noreferrer" target="_blank" href="tel:+963114003">
                <CallIcon />
                <div>+963 11 4003</div>
              </a>
            </Col>
            <Col span={24} lg={6}>
              <a
                rel="noreferrer"
                target="_blank"
                href="mailto:info@izone-me.com"
              >
                <EmailIcon />
                <div>info@izone-me.com</div>
              </a>
            </Col>
            <Col span={24} lg={6}>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://goo.gl/maps/yhcBkznWfqENVqL3A"
              >
                <LocationIcon />
                <div>Damascus - Freezone</div>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default ContactUs;
