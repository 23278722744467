import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import client1 from "../../assets/images/companies/IZONEclients (1).png";
import client2 from "../../assets/images/companies/IZONEclients (2).png";
import client3 from "../../assets/images/companies/IZONEclients (3).png";
import client4 from "../../assets/images/companies/IZONEclients (4).png";
import client5 from "../../assets/images/companies/IZONEclients (5).png";
import client6 from "../../assets/images/companies/IZONEclients (6).png";
import client7 from "../../assets/images/companies/IZONEclients (7).png";
import client8 from "../../assets/images/companies/IZONEclients (8).png";
import client9 from "../../assets/images/companies/IZONEclients (9).png";
import client10 from "../../assets/images/companies/IZONEclients (10).png";
import client11 from "../../assets/images/companies/IZONEclients (11).png";
import client12 from "../../assets/images/companies/IZONEclients (12).png";
import client13 from "../../assets/images/companies/IZONEclients (13).png";
import client14 from "../../assets/images/companies/IZONEclients (14).png";
import client15 from "../../assets/images/companies/IZONEclients (15).png";
import client16 from "../../assets/images/companies/IZONEclients (16).png";
import client17 from "../../assets/images/companies/IZONEclients (17).png";
import client18 from "../../assets/images/companies/IZONEclients (18).png";
import client19 from "../../assets/images/companies/IZONEclients (19).png";
import client20 from "../../assets/images/companies/IZONEclients (20).png";
import client21 from "../../assets/images/companies/IZONEclients (21).png";

const OurClients = () => {
  // const { data, isLoading } = useCompanies()

  return (
    <div className="clients" id="our-clients">
      <div className="title">Our Clients</div>
      <Row justify="center">
        <Col span={19}>
          <Row className="client-container" gutter={[60, 60]}>
            {false ? ( // replace false with isLoading
              <Col span={24}>
                <div className="loading">
                  <LoadingOutlined />
                </div>
              </Col>
            ) : (
              <>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client1} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client2} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client3} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client4} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client5} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client6} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client7} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client21} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client8} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client9} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client10} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client11} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client12} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client13} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client14} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client15} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client16} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client17} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client18} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client19} alt="" />
                </Col>
                <Col span={8} md={6} lg={4}>
                  <img className="client-logo" src={client20} alt="" />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default OurClients;
