import { ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import AOS from "aos";
import { useEffect } from "react";
import { ScrollTo } from "../../services/scrollto";
import { ReactComponent as GooglePlayDark } from "../../assets/images/GooglePlay_dark.svg";
import { ReactComponent as AppStoreDark } from "../../assets/images/AppStore_dark.svg";
const WelcomeTo = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="welcometo">
      <Row style={{ height: "100%" }}>
        <Col
          className="container"
          span={20}
          offset={3}
          lg={{ span: 12, offset: 4 }}
        >
          <div
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="300"
            className="welcome"
          >
            Welcome to
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="500"
            className="izone"
          >
            <span style={{ color: "#1367b0" }}>I</span>ZONE{" "}
            <span style={{ color: "#1367b0" }}>solutions</span>
          </div>
          <p
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="700"
            className="text"
          >
            is an IT outsourcing company delivers customer-service.
            <br />
            <span className="blue">IZONE</span> Technology Response Team is a
            real partner in your business technology.
          </p>
          <div
            data-aos="fade-right"
            data-aos-duration="1300"
            data-aos-delay="900"
            className="check"
          >
            <span
              onClick={() => {
                ScrollTo("services");
              }}
            >
              Check Our Services
              <span className="blue">
                <ArrowRightOutlined />
              </span>
            </span>
          </div>
          <h1 style={{ color: "#1367b0", fontWeight: "normal" }}>
            Download Our App
          </h1>
          <div className="applink">
            <a
              href="https://play.google.com/store/apps/details?id=com.izone.ticketing_system_izon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GooglePlayDark />
            </a>
            <a
              href="https://apps.apple.com/app/izone-soluations/id1644071197"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppStoreDark />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default WelcomeTo;
