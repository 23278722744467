import { useQuery, useQueryClient } from 'react-query'
import { useMutation } from 'react-query'
import { notification } from 'antd'
import { PostQuestion } from '../../../services/question'
import isError from '../../../utils/helpers/is-error'

export default function AddQuestion() {
  const queryClient = useQueryClient()
  return {
    ...useMutation(PostQuestion, {
      onSuccess: async res => {
        if (!isError(res)) {
          notification.open({
            type: 'success',
            message: 'Your question has been sent successfully',
          })
          // queryClient.invalidateQueries("tickets");
        }
      },
    }),
  }
}
