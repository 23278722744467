import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useCaptcha from "../../hooks/query/captcha/useCaptcha";
import AddQuestion from "../../hooks/query/question/addQuestion";
import { useWidth } from "../../utils/helpers/use-is-mobile";

interface props {
  question: string;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

const QuestionModal = ({
  question,
  isModalVisible,
  setIsModalVisible,
}: props) => {
  const [form] = Form.useForm();

  const { isMobile } = useWidth();

  const [Error, setError] = useState<any>({});

  const captcha = useCaptcha();
  const Post = AddQuestion();

  useEffect(() => {
    captcha.refetch();
    setError({});
  }, [isModalVisible]);
  useEffect(() => {
    form.resetFields();
  }, [question, isModalVisible]);

  const FinishQuestion = (e: any) => {
    let form = e;
    form.key = captcha?.data?.data.key;
    Post.mutateAsync(form)
      .then(() => {
        setIsModalVisible(false);
        form.resetFields();
      })
      .catch((res) => {
        if (res?.errors?.captcha[0] === "validation.captcha_api") {
          captcha.refetch();
        }
        if (res?.errors) setError(res?.errors);
      });
  };

  return (
    <Modal
      title="Send a Question"
      className="modal"
      open={isModalVisible}
      width={isMobile ? "95%" : "50%"}
      centered
      onCancel={() => {
        setIsModalVisible(false);
        form.resetFields();
      }}
      footer={[
        <>
          <Button type="default" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>
          <Button
            loading={Post.isLoading}
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            Send
          </Button>
        </>,
      ]}
    >
      <Form
        onFinish={FinishQuestion}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        requiredMark={false}
      >
        <Form.Item
          validateStatus={Error?.email ? "error" : "success"}
          help={Error?.email && Error?.email[0]}
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input
            onChange={() => {
              const err = { ...Error };
              delete err.email;
              setError(err);
            }}
            type={"email"}
            placeholder={"Enter your email"}
          />
        </Form.Item>
        <Form.Item
          initialValue={question}
          label="Question"
          name="content"
          rules={[{ required: true, message: "Please input your question!" }]}
        >
          <TextArea rows={5} placeholder={"Write Your Question"} />
        </Form.Item>
        <Row>
          <Col offset={8} span={16}>
            <div className="captcha-img">
              {captcha?.isFetching ? (
                <LoadingOutlined />
              ) : (
                <img src={captcha?.data?.data?.img} alt="captcha" />
              )}
            </div>
          </Col>
        </Row>
        <Form.Item
          validateStatus={Error?.captcha ? "error" : "success"}
          help={Error?.captcha && "Captcha code is wrong"}
          label="Captcha code"
          name="captcha"
          rules={[{ required: true, message: "Please enter the code!" }]}
        >
          <Input
            onChange={() => {
              const err = { ...Error };
              delete err.captcha;
              setError(err);
            }}
            placeholder={"Enter what you see"}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default QuestionModal;
