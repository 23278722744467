import ContactUs from "../components/contactus";
import OurClients from "../components/ourclients";
import Products from "../components/products";
import Services from "../components/services";
import SideSlider from "../components/sideslider";
import Solutions from "../components/solutions";
import WelcomeTo from "../components/welcometo";
import { useWidth } from "../utils/helpers/use-is-mobile";

const Home = () => {
  const { isMobile } = useWidth();
  return (
    <>
      {!isMobile && <SideSlider />}
      <WelcomeTo />
      <OurClients />
      <Services />
      <Products />
      <Solutions />
      <ContactUs />
    </>
  );
};

export default Home;
