import { Button, Carousel, Col, Row } from "antd";
import { ReactComponent as NextArrow } from "../../assets/images/nextArrow.svg";
import { ReactComponent as PrevArrow } from "../../assets/images/prevArrow.svg";
import { ReactComponent as Virtual } from "../../assets/images/virtual.svg";
import { ReactComponent as Consulting } from "../../assets/images/consulting.svg";
import { ReactComponent as Network } from "../../assets/images/network.svg";
import { ReactComponent as Storage } from "../../assets/images/storage.svg";
import { ReactComponent as Security } from "../../assets/images/security.svg";
import { ReactComponent as DataCenter } from "../../assets/images/datacenter.svg";
import { ReactComponent as DataRecovery } from "../../assets/images/datarecovery.svg";
import { ReactComponent as Surveillance } from "../../assets/images/surveillance.svg";
import { ReactComponent as Server } from "../../assets/images/server.svg";

const Services = () => {
  return (
    <div className="servicesSlider" id="services">
      <div className="title">Services</div>
      <Row justify="center">
        <Col span={17}>
          <Carousel
            arrows={true}
            responsive={[
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 567,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ]}
            nextArrow={
              <Button>
                <NextArrow />
              </Button>
            }
            prevArrow={
              <Button>
                <PrevArrow />
              </Button>
            }
            slidesToShow={5}
            slidesToScroll={5}
            dots={false}
            className="slider"
          >
            <div className="service">
              <div className="img">
                <Virtual />
              </div>
              <div className="text">Virtualization</div>
            </div>
            <div className="service">
              <div className="img">
                <Consulting />
              </div>
              <div className="text">Network Consulting Services</div>
            </div>
            <div className="service">
              <div className="img">
                <Network />
              </div>
              <div className="text">Networking made simple</div>
            </div>
            <div className="service">
              <div className="img">
                <Storage />
              </div>
              <div className="text">Storage Services</div>
            </div>
            <div className="service">
              <div className="img">
                <Security />
              </div>
              <div className="text">Security</div>
            </div>
            <div className="service">
              <div className="img">
                <DataCenter />
              </div>
              <div className="text">Data Center Facilities Services</div>
            </div>
            <div className="service">
              <div className="img">
                <DataRecovery />
              </div>
              <div className="text">Data Recovery</div>
            </div>
            <div className="service">
              <div className="img">
                <Surveillance />
              </div>
              <div className="text">
                Network & Surveillance system infrastructure
              </div>
            </div>
            <div className="service">
              <div className="img">
                <Server />
              </div>
              <div className="text">server & Network infrastructure</div>
            </div>
          </Carousel>
        </Col>
      </Row>
    </div>
  );
};
export default Services;
