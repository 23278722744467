import ApiService from '../../utils/api/api-service'
import ApiResult from '../../utils/api/models/api-result'
import isError from '../../utils/helpers/is-error'

class QuestionService extends ApiService {
  constructor() {
    super({ baseURL: 'https://app.izone-me.com/izone_platform_development/public/' })
  }

  public getCaptcha = async (): Promise<ApiResult<any>> => this.get<any>('/api/reload-captcha')
  public Post = async (data: any): Promise<ApiResult<any>> => this.post<any>('/api/guest-messages', data)
}

const questionService = new QuestionService()

export const FetchCaptcha = async () => {
  const result: any = await questionService.getCaptcha()
  if (isError(result)) {
    // ApiErrorNotification(result)
  } else {
    return result
  }
}

export const PostQuestion = async (data: any) => {
  const result: any = await questionService.Post(data)
  if (isError(result)) {
    // ApiErrorNotification(result)
    throw result
  } else {
    return result
  }
}
