import "aos/dist/aos.css";
import "./styles/main.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter, Route, Routes } from "react-router-dom";
import Navbar from "./layout/navbar";
import Footer from "./layout/footer";
import Home from "./pages";
import PrivacyPolicy from "./pages/privacy-policy";

function App() {
  const queryClient = new QueryClient();
  return (
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </QueryClientProvider>
    </HashRouter>
  );
}

export default App;