import { StarTwoTone } from "@ant-design/icons";
import { Rate } from "antd";

interface props {
  imgsrc: string;
  rate: number;
  productTitle?: string;
  brand?: string;
}

const ProdactCard = ({ rate, imgsrc, productTitle, brand }: props) => {
  return (
    <div className="pordactCard">
      <img className={"productImg"} src={imgsrc} alt="" />
      <div className="info">
        <Rate disabled defaultValue={rate} character={<StarTwoTone />} />
        <div className="name">
          {productTitle ? productTitle : "Product Title Goes Here"}
        </div>
        <div className="brand">{brand ? brand : "BRAND"}</div>
      </div>
    </div>
  );
};
export default ProdactCard;
