import { MenuOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Image, Row } from "antd";
import { useState } from "react";
import { ScrollTo } from "../../services/scrollto";
import { useWidth } from "../../utils/helpers/use-is-mobile";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/images/home.svg";
import { ReactComponent as ServicesIcon } from "../../assets/images/services.svg";
import { ReactComponent as ToolsIcon } from "../../assets/images/tools.svg";
import { ReactComponent as WhitePhoneIcon } from "../../assets/images/whitephone.svg";
import { ReactComponent as WhiteEmailIcon } from "../../assets/images/whiteemail.svg";
import { ReactComponent as WhatsAppIcon } from "../../assets/images/whatsapp.svg";
import { ReactComponent as TelegramIcon } from "../../assets/images/telegram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import logo from "../../assets/images/logo.png";
const Navbar = () => {
  const location = useLocation();
  const { isMobile } = useWidth();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="navbar">
      <div className="line" />
      <Row className="nav" justify="center">
        <Col span={24} lg={19}>
          <Row>
            <Col span={15} lg={4}>
              <Link to={"/"}>
                <img className="logo" src={logo} alt="IZONE Solutions" />
              </Link>
            </Col>
            {!isMobile && (
              <Col span={19}>
                <ul className="navlinks">
                  <li className="link">
                    {location.pathname === "/" ? (
                      <span
                        onClick={() => {
                          ScrollTo("home");
                        }}
                      >
                        <HomeIcon className="linkicon" />
                        <div className="linktext">HOMEPAGE</div>
                      </span>
                    ) : (
                      <Link to={"/"}>
                        <div>
                          <HomeIcon className="linkicon" />
                          <div className="linktext">HOMEPAGE</div>
                        </div>
                      </Link>
                    )}
                  </li>
                  <li className="link">
                    {location.pathname === "/" ? (
                      <span
                        onClick={() => {
                          ScrollTo("services");
                        }}
                      >
                        <ServicesIcon className="linkicon" />
                        <div className="linktext">SERVICES</div>
                      </span>
                    ) : (
                      <Link to={"/"}>
                        <div>
                          <ServicesIcon className="linkicon" />
                          <div className="linktext">SERVICES</div>
                        </div>
                      </Link>
                    )}
                  </li>
                  <li className="link">
                    {location.pathname === "/" ? (
                      <span
                        onClick={() => {
                          ScrollTo("solutions");
                        }}
                      >
                        <ToolsIcon className="linkicon" />
                        <div className="linktext">SOLUTIONS</div>
                      </span>
                    ) : (
                      <Link to={"/"}>
                        <div>
                          <ToolsIcon className="linkicon" />
                          <div className="linktext">SOLUTIONS</div>
                        </div>
                      </Link>
                    )}
                  </li>
                  {/* <li className='link'>
                    <a href='#'>
                      <img src='/images/tools.svg' className='linkicon' />
                      <div className='linktext'>ABOUT US</div>
                    </a>
                  </li> */}
                </ul>
              </Col>
            )}
            {isMobile && (
              <Col span={3} offset={6}>
                <Button className="drawerbtn" onClick={showDrawer}>
                  <MenuOutlined />
                </Button>

                <Drawer
                  className="drawer"
                  width={"90%"}
                  title={
                    <Image
                      preview={false}
                      height="3rem"
                      src={logo}
                      alt="IZONE Solution"
                    />
                  }
                  placement="right"
                  onClose={onClose}
                  open={visible}
                >
                  <ul className="menu">
                    <li className="link" onClick={onClose}>
                      {location.pathname === "/" ? (
                        <span
                          onClick={() => {
                            ScrollTo("home");
                          }}
                        >
                          <HomeIcon className="linkicon" />
                          <div className="linktext">HOMEPAGE</div>
                        </span>
                      ) : (
                        <Link to={"/"}>
                          <div>
                            <HomeIcon className="linkicon" />
                            <div className="linktext">HOMEPAGE</div>
                          </div>
                        </Link>
                      )}
                    </li>
                    <li className="link" onClick={onClose}>
                      {location.pathname === "/" ? (
                        <span
                          onClick={() => {
                            ScrollTo("services");
                          }}
                        >
                          <ServicesIcon className="linkicon" />
                          <div className="linktext">SERVICES</div>
                        </span>
                      ) : (
                        <Link to={"/"}>
                          <div>
                            <ServicesIcon className="linkicon" />
                            <div className="linktext">SERVICES</div>
                          </div>
                        </Link>
                      )}
                    </li>
                    <li className="link" onClick={onClose}>
                      {location.pathname === "/" ? (
                        <span
                          onClick={() => {
                            ScrollTo("solutions");
                          }}
                        >
                          <ToolsIcon className="linkicon" />
                          <div className="linktext">SOLUTIONS</div>
                        </span>
                      ) : (
                        <Link to={"/"}>
                          <div>
                            <ToolsIcon className="linkicon" />
                            <div className="linktext">SOLUTIONS</div>
                          </div>
                        </Link>
                      )}
                    </li>
                    {/* <li className='link'>
                      <a href='#'>
                        <img src='/images/tools.svg' className='linkicon' />
                        <div className='linktext'>ABOUT US</div>
                      </a>
                    </li> */}
                  </ul>
                  <ul className="contact">
                    <li className="contactlink" onClick={onClose}>
                      <Link to="/privacy-policy">
                        <div style={{ cursor: "pointer" }}>Privacy Policy</div>
                      </Link>
                    </li>
                    <li className="contactlink" onClick={onClose}>
                      <span
                        onClick={() => {
                          ScrollTo("contact-us");
                        }}
                      >
                        <WhitePhoneIcon height=".9rem" /> Contact Us
                      </span>
                    </li>
                    <li className="contactlink">
                      <a
                        onClick={onClose}
                        rel="noreferrer"
                        target="_blank"
                        href="https://api.WhatsApp.com/send?phone=+963956743743"
                      >
                        <WhatsAppIcon height=".9rem" /> +963 956 743 743
                      </a>
                    </li>
                    <li className="contactlink">
                      <a
                        onClick={onClose}
                        rel="noreferrer"
                        target="_blank"
                        href="mailto:info@izone-me.com"
                      >
                        <WhiteEmailIcon height=".9rem" /> info@izone-me.com
                      </a>
                    </li>
                    <li className="contactlink">
                      <div className="follow">
                        <div>Follow Us</div>
                        <a
                          onClick={onClose}
                          href="https://t.me/izone_solutions_sy"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <TelegramIcon />
                        </a>
                        <a
                          onClick={onClose}
                          href="https://www.facebook.com/izone.solutions.sy"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <FacebookIcon />
                        </a>
                      </div>
                    </li>
                  </ul>
                </Drawer>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Navbar;
