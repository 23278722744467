import { Col, Row } from 'antd'

const Solutions = () => {
  return (
    <div className='solutions' id='solutions'>
      <Row justify='center'>
        <Col span={20}>
          <div className='title'>Solutions We Offers</div>
          <Row gutter={[60, 60]}>
            <Col lg={12}>
              <p className='izone'>IZONE has extensive experience in the delivery of consulting services, promoting, stimulating and supporting clients in setting up and running their IT environment and information systems. IZONE solutions basically involve the following key domains:</p>
            </Col>
            <Col lg={6}>
              <div className='solution'>Project Management</div>
              <div className='text'>Time management, site survey, risk and configuration management.</div>
              <div className='more'>Read More</div>
            </Col>
            <Col lg={6}>
              <div className='solution'>Quality Assurance, Management and Audit</div>
              <div className='text'>Project Quality Plans (PQP), functional and quality tests, quality assessments.</div>
              <div className='more'>Read More</div>
            </Col>
            <Col lg={6}>
              <div className='solution'>Enterprise Architecture</div>
              <div className='text'>Portfolio Analysis of information systems, analysis of organizational structures.</div>
              <div className='more'>Read More</div>
            </Col>
            <Col lg={6}>
              <div className='solution'>Enterprise Network Solutions</div>
              <div className='text'>solve the toughest challenges in networking and design a data center.</div>
              <div className='more'>Read More</div>
            </Col>
            <Col lg={6}>
              <div className='solution'>Sales technique</div>
              <div className='text'>provide offers real-time for sales issues via phone or email during engagement. </div>
              <div className='more'>Read More</div>
            </Col>
            <Col lg={6}>
              <div className='solution'>Intelligent Surveillance and control solutions</div>
              <div className='text'>provide the highest quality products HDCVI technology of monitoring systems.</div>
              <div className='more'>Read More</div>
            </Col>
            <Col lg={6}>
              <div className='solution'>Premium Power Systems</div>
              <div className='text'>protect data storage environment, infrastructure devices, critical loads.</div>
              <div className='more'>Read More</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
export default Solutions
