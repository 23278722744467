export function ScrollTo(id: string) {
  let Element: any = window.document.getElementById(id)
  setTimeout(
    () =>
      window.scrollTo({
        top: Element?.offsetTop,
        left: 0,
        behavior: 'smooth',
      }),
    400
  )
}
